import React, { Component } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        className="location-map"
        scrollwheel={false}
        zoom={14}
        initialCenter={{
          lat: 49.53253,
          lng: -96.734952,
        }}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Marker onClick={() => {}} name={'Our location'} />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBwRlKYjNup8-fUb97LSUz5xV0h4Mxk0AI',
})(MapContainer)
