import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Header, Footer } from '../components/Common'
import { PageSection, BannerImage } from '../components/Layout'
import { Map } from '../components/Maps'
import { theme } from '../styles'

const ContactPage = ({ data }) => {
  const pageData = data.allPrismicContactPage.nodes[0].data
  const commonData = data.allPrismicCommonPageContent.nodes[0].data

  return (
    <div>
      <Header logoUrl={commonData.logo_image.url} />
      <BannerImage
        imageFluid={pageData.banner_image.localFile.childImageSharp.fluid}
        titleHtml={pageData.page_title.html}
      />
      <PageSection>
        <PageContentContainer>
          <MapContainer>
            <Map />
          </MapContainer>
          <ContactInfo
            dangerouslySetInnerHTML={{ __html: pageData.contact_content.html }}
          />
        </PageContentContainer>
      </PageSection>
      <Footer data={commonData.footer_text_content.html} />
    </div>
  )
}

const MapContainer = styled.div`
  width: 60%;
  height: 600px;
  margin: 0 40px 0 0;

  & > div {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${theme.breakpoints.l}) {
    height: 400px;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    height: 300px;
    width: 100%;
    margin: 0 0 40px 0;
  }
`

const PageContentContainer = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
  }
`

const ContactInfo = styled.div`
  text-align: left;

  h2 {
    font-size: 26px;
  }
`

export const query = graphql`
  query ContactPageQuery {
    allPrismicCommonPageContent {
      nodes {
        data {
          footer_text_content {
            html
          }
          logo_image {
            url
          }
        }
      }
    }
    allPrismicContactPage {
      nodes {
        data {
          page_title {
            html
          }
          contact_content {
            html
          }
          banner_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage
